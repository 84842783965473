<template>
  <div>
    <!-- 打印记录 -->
    <order v-if="flag == '快递单'" @getOrde="getOrderFlag" />
    <deliverGoods v-else-if="flag == '发货单'" @getDelGoods="getDelGoodsFlag" />
  </div>
</template>
<script>
import deliverGoods from "../components/deliverGoods.vue";
import order from "../components/order.vue";
export default {
  components: {
    deliverGoods, //发货单
    order, //快递单
  },
  data() {
    return {
      flag: "快递单",
    };
  },
  methods: {
    getOrderFlag(flag) {
      if (flag !== "快递单") this.flag = flag;
    },
    getDelGoodsFlag(flag) {
      if (flag !== "发货单") this.flag = flag;
    },
  },
};
</script>